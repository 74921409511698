@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {

    html {
        scroll-behavior: smooth;
    }

    body {
        @apply font-sans;
        @apply text-gray-600;

    }

    h1 {
        @apply text-3xl;
        @apply font-sans;
        @apply text-gray-700;

    }

    h2 {
        @apply text-2xl;
        @apply font-sans;
        @apply text-gray-700;
    }

    h3 {
        @apply text-xl;
        @apply font-sans;
        @apply text-gray-700;
    }

    h4 {
        @apply text-lg;
        @apply font-sans;
        @apply text-gray-700;
    }

    p {
        font-size: 1rem;
        line-height: 1.5rem;
        margin: 1.5em 0 1.5em 0;
        font-weight: 400;
    }

    p a {
        @apply underline;
        @apply text-yellow-600;
    }

    ul,
    ol {
        margin: 0 0 1.5em 1.5em;
        list-style-position: inside;
    }

    ol {
        @apply list-inside;
        @apply list-decimal;
    }

    ul {
        @apply list-inside;
        @apply list-disc;        
    }

    li>ul,
    li>ol {
        margin-bottom: 0;
        margin-left: 1.5em;
    }

    img {
        height: auto;
        max-width: 100%;
    }

    figure {
        margin: 1em 0;
    }

    table {
        margin: 0 0 1.5em;
        width: 100%;
    }

    .ulplus:before {
        content: "\2713";
        font-weight: 700;
        color: inherit;
        padding: 0 1rem 0 0
    }

    .ulminus:before {
        content: "\2717";
        font-weight: 700;
        color: inherit;
        padding: 0 1rem 0 0
    }

    blockquote {
        border-left: 1px solid #999;
        position: relative;
        padding-left: 1em;
        margin: 1em 2em;
    }

    blockquote:before {
        content: "\201C";
        position: absolute;
        font-size: 6em;
        left: -38px;
        top: -23px;
    }

    .h2 {
        @apply text-2xl;
        @apply font-sans;
        @apply text-gray-700;
    }

    .h3 {
        @apply text-xl;
        @apply font-sans;
        @apply text-gray-700;
    }

    .h4 {
        @apply text-lg;
        @apply font-sans;
        @apply text-gray-700;
    }

}
